
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import { Watch } from 'vue-property-decorator';
import FileUploadList from "@/components/scope/file-upload-list.vue";

const app = namespace("app");
const store = namespace("store");
const campaign = namespace("campaign");
const coupon = namespace("coupon");
const fission = namespace("fission");
const base = namespace("base");
const marketing = namespace("marketing");
const management = namespace("management");
const defaultBg = "rgb(236,246,245)";
const defaultBannerUrl =
  "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/speedbiz/minipro/accumulate-banner.jpg";
@Component({
  components: { FileUpload, FileUploadList,Editor }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action createSaveCampaign;
  @campaign.Action queryCampaignDetail;
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @coupon.Action queryList;
  @app.Mutation setPBPX;
  @marketing.Action queryCardTemplateList;
  @marketing.Action newActivitySave;        //活动保存
  @marketing.Action newActivityDetail;      //活动详情
  @fission.Action insertFission;
  @fission.Action queryFissionCampaign;
  @fission.Action updateFission;
  @management.Action getStoreList
  @management.Action getStoreCouponList
  @management.Action crowdManagementList
  emptyToLine = emptyToLine;
  defaultUserFixTemplateUrl: string = "https://speedbiz-saas-public.oss-cn-beijing.aliyuncs.com/dev/speedbiz/others/importTemplate/user_activity_fix_user.xlsx";

  campaignForm: any = {
    id: "defalut", // 上传图片用的
    activityName: "",               //活动名称
    // campaignBeginTime: "",
    // campaignEndTime: "",
    posterImage: "",                //分享海报图片
    bannerImage: "",                //banner图片
    shareImage: "",                 //分享图片
    shareDesc: "",                  //分享说明
    participateLimit: 1,            //是否限购
    participateLimitCount: "",      //限购次数
    skuBuyLimit: 1,                 //购买类型 1、打包 2、每组购买 3、限制购买
    skuBuyLimitCount: "",           //限制购买数量
    startTime: "",                  //开始时间
    endTime: "",                    //结束时间
    isShow: 1,                     //是否前端展示
    // isCoupon: "1",
    context: "",
    subTitle: "",
    // campaignImageColor: "",
    // fissionPrize: {
    //   bindCode: "",
    //   prizeAmount: "",
    //   prizeQty: ""
    // },
    // 非业务直接参数
    time: null,
    isShare: 0,
    userScope: 0,
    crowdNos:[],//人群编号,可选多个
    fixUserFiles:[],//固定用户excel地址
    saleLimitNum: null,
    // bannerType: "customize", // default customize
    // storeTypes: ""
    cycleLimit: 1,
    cycleLimitCount: 0
  };
  dayjs = dayjs;
  storeTypesList = [];
  newStoreTypesList = [];
  showCouponSelector: boolean = false;
  showCouponPopup: boolean = false;
  storeList: any = [];
  storeCouponList: any = [];
  crowdList: any = [];
  campaignFormRules: any = {
    activityName: [
      { required: true, message: t("v210801.please-enter-the-title"), trigger: "blur" },
      { max: 14, message: t("v210801.the-maximum-length"), trigger: "blur" }
    ],
    time: {
      type: "array",
      required: true,
      message: '请选择活动时间',
      trigger: "change"
    },
    bannerImage: { required: true, validator: this.checkFrontImage },
    shareImage: { required: true, validator: this.checkFrontImage },
    context: [{ required: true, message: t("v210831.please-enter-the-activity-content"), trigger: "blur" }],
    // storeTypes: { required: true, message: t("v210831.at-least-one-usable-store"), trigger: "blur" }
  };

  coupon_total: number = 0;
  coupon_pageNum: number = 1;
  coupon_pageSize: number = 10;
  // 活动时间选择
  timeChange(e){
    console.log(e)
    this.campaignForm.startTime = e[0];
    this.campaignForm.endTime = e[1];
  }
  checkFrontImage(rule, value, callback) {
    if (!value) {
      callback(new Error(t("v210831.please-upload-pictures")));
    } else {
      callback();
    }
  }

  handleExcelUploadSuccess(url) {
    // 要添加的对象
    const newFile = { fileUrl: url };
    this.campaignForm.fixUserFiles.push(newFile);
  }

  handleRemoveSuccess(removeIndex) {
    this.campaignForm.fixUserFiles.splice(removeIndex, 1);
  }
  handleBannerChange(val) {
    if (val === "default") {
      this.campaignForm.campaignImageColor = defaultBg;
      this.campaignForm.bannerImage = defaultBannerUrl;
      this.defaultBannerList = [];
    } else {
      this.$nextTick(() => {
        this.campaignForm.bannerImage = "";
      });
    }
  }
  get breadData() {
    return [
      { name: t("v210801.fission-activity"), path: "/management/fission/fission-campagin" },
      {
        name: this.isAdd ? t("v210801.new-fission-activity") : t("v210801.edit-fission-activity")
      }
    ];
  }
  searchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
    storeCode: ''
    // cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
  };
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  handleSearchCoupon(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.storeCouponListFn();
  }
  selectedRow: any = [];
  selectedRowCoupon: any = [];
  confirmSelecteRow: any = [];
  confirmSelecteCoupon: any = [];
  // confirmSelecteRow: any = {
  //   bindCode: undefined,
  //   prizeAmount: undefined,
  //   prizeQty: undefined
  // };
  handleRowSelect(select,rows) {
    if(this.selectedRow.length>0){
      let isPush = this.selectedRow.some(item => {
        return rows.cardTemplateCode == item.cardTemplateCode
      })
      if(!isPush){
        this.selectedRow.push(rows)
      }
    }else{
      this.selectedRow.push(rows)
    }
  }

  @Watch('campaignForm.userScope')
  onUserScopeChange(newVal: number) {
    console.log("onUserScopeChange::" + newVal);

    if (newVal !== 3) {
      this.campaignForm.crowdNo = "";
    }

    if (newVal !== 4) {
      this.campaignForm.fixUserFiles = [];
    }
  }
  handleRowSelectCoupon(select,rows){
    if(this.selectedRowCoupon.length>0){
      let isPush = this.selectedRowCoupon.some(item => {
        return rows.activityCode == item.activityCode
      })
      if(!isPush){
        this.selectedRowCoupon.push(rows)
      }
    }else{
      this.selectedRowCoupon.push(rows)
    }
  }
  checkable(row) {
    // return !Object.keys(this.selectedRow).length || this.selectedRow.cardTemplateCode === row.cardTemplateCode;
  }
  handleSelectedCoupon() {
    console.log(this.selectedRow)
    this.mix_pageNum = 1;
    if (this.selectedRow.length<1) {
      return this.$message.error(t("v210831.please-select-a-template-card"));
    }
    // this.confirmSelecteRow = Object.assign({}, this.selectedRow);
    this.selectedRow.sort(function(a, b) {
      return a.carryingAmount - b.carryingAmount;
    });
    this.confirmSelecteRow = JSON.parse(JSON.stringify(this.selectedRow));
    // this.campaignForm.storeTypes = this.confirmSelecteRow.storeTypes;
    // let newList = this.storeTypesList
    // this.newStoreTypesList = this.storeTypesList.filter(item => {
    //   if (this.confirmSelecteRow.storeTypes.some(find => find === item.dataValue)) {
    //     return item;
    //   }
    // });
    this.showCouponSelector = false;
  }
  // 优惠券选择
  handleSelectedStoreCoupon() {
    console.log(this.selectedRowCoupon)
    this.mix_pageNum = 1;
    if (this.selectedRowCoupon.length<1) {
      return this.$message.error('请选择一张优惠券');
    }
    // this.selectedRowCoupon.sort(function(a, b) {
    //   return a.carryingAmount - b.carryingAmount;
    // });
    this.confirmSelecteCoupon = JSON.parse(JSON.stringify(this.selectedRowCoupon));
    this.showCouponPopup = false;
  }
  get couponSearchList() {
    return [
    {
        label: t("v210801.card-template-name"),
        type: "input",
        value: "",
        prop: "cardTemplateName"
      },
    ];
  }
  get couponSearch() {
    return [
      {
        label: "选择门店",
        type: "select-search",
        value: this.searchForm.storeCode,
        prop: "storeCode",
        placeholder: "请选择门店",
        selectList: this.storeList,
        searchFun: this.searchStore
      }
    ];
  }
  campaignStatusList: any = [];
  get isAdd() {
    return this.$route.name === "fissionCampaginAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  get copyCode() {
    return this.$route.query.copyCode;
  }
  defaultBannerList: any = [];
  defaultShareList: any = [];
  defaultPosterList: any = [];
  defaultFixUserList: any = [];
  init() {
    console.log(this.id)
    this.searchStore();

    // 获取人群列表
    this.crowdListFn();

    // 获取详情
    if(this.id){
      this.newActivityDetail({activityCode: this.id}).then(res => {
        console.log('sadasdas',Object.keys(res.data))
        Object.keys(res.data).forEach(key => {
          this.campaignForm[key] = res.data[key];
          this.confirmSelecteRow = res.data.activityCardTemplateList || [];
          this.confirmSelecteCoupon = res.data.activityCouponResultList || [];
          this.campaignForm.time = [res.data.startTime, res.data.endTime]
          this.defaultBannerList = [{url: res.data.bannerImage}];
          this.defaultShareList = [{url: res.data.shareImage}];
          this.defaultPosterList = [{url: res.data.posterImage}];

          if(res.data.fixUserFiles){
            this.defaultFixUserList = res.data.fixUserFiles.map(item => {
              let fileName = item.fileUrl.substring(item.fileUrl.lastIndexOf("/") + 1);
              return { url: item.fileUrl, name: fileName };
            });
          }
        })
        
      })
    }
    this.getDictionaryList("store_type").then(data => {
      this.storeTypesList = data;
    });
    // this.handleBannerChange("default");
    this.getDictionaryList("coupon_activity_status").then(data => {
      this.campaignStatusList = data.filter(item => item.value !== "05" && item.value !== "07");
    });
    return
    if (!this.isAdd || this.copyCode) {
      this.queryFissionCampaign({ campaignCode: this.id || this.copyCode }).then(data => {
        data.data.time = [
          dayjs(data.data.campaignBeginTime).format("YYYYMMDDHHmmss"),
          dayjs(data.data.campaignEndTime).format("YYYYMMDDHHmmss")
        ];
        if (this.copyCode) {
          delete data.data.campaignCode;
        }
        if (data.data.bannerImage === defaultBannerUrl) {
          this.campaignForm.bannerType = "default";
        } else {
          this.campaignForm.bannerType = "customize";
          this.defaultBannerList = [{ url: data.data.bannerImage }];
        }
        this.defaultShareList = [{ url: data.data.shareImage }];
        data.data.storeTypes = data.data.storeTypes.split(",");
        this.newStoreTypesList = this.storeTypesList.filter(item => {
          if (data.data.storeTypes.some(find => find === item.dataValue)) {
            return item;
          }
        });
        this.confirmSelecteRow = data.data.fissionPrize
          ? Object.assign(data.data.fissionPrize.cardTemplateResult, data.data.fissionPrize)
          : {};
        Object.assign(this.campaignForm, data.data);
      });
    }
  }
  mounted() {}
  handleRemoveCoupon(index) {
    this.selectedRow = [];
    // this.confirmSelecteRow = [];
    this.confirmSelecteRow.splice(index,1)
  }
  handleRemoveStoreCoupon(index) {
    this.selectedRowCoupon = [];
    // this.confirmSelecteCoupon = [];
    this.confirmSelecteCoupon.splice(index,1)
  }
  handleBannerUploadSuccess(url) {
    (this.$refs.campaignForm as any).validateField("bannerImage");
    this.getImageColor(url);
  }
  handleShowCard() {
    this.showCouponSelector = true;
    this.loadListData();
  }
  handleShowCoupon() {
    this.storeCouponListFn();
    this.showCouponPopup = true;
  }

  // 获取门店列表
  searchStore(val = ''){
    console.log(val)
    this.getStoreList({
      storeTypeList: ['3', '8', '9'],
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(res => {
      this.storeList = res.data.list ? res.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
        this.searchForm.storeCode = this.storeList[0].storeCode
        this.storeCouponListFn();
    })
  }
  couponSizeChange(e){
    this.coupon_pageSize = e;
    this.storeCouponListFn();
    
  }
  couponPageChange(e){
    this.coupon_pageNum = e;
    this.storeCouponListFn();
  }
  // 获取优惠券列表
  storeCouponListFn(){
    this.mix_pageNum = 1;
    // this.mix_pageSize = 99;
    this.getStoreCouponList({
      storeCode: this.searchForm.storeCode,
      pageNum: this.coupon_pageNum,
      pageSize: this.coupon_pageSize
    }).then(res => {
      this.storeCouponList = res.data.list;
      this.coupon_total = res.data.total;
    })
  }

  //人群列表
  crowdListFn(){
    this.mix_pageNum = 1;
    this.mix_pageSize = 999999;
    this.crowdManagementList({
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(res => {
      console.log(res)
      this.crowdList = JSON.parse(JSON.stringify(res.data.list))
      return res
    })
  }
  getImageColor(url) {
    console.log(url);
    const _this = this;
    let canvas = document.createElement("canvas"),
      ctxt = canvas.getContext && canvas.getContext("2d");
    var img = new Image();
    // 要求跨域处理
    img.crossOrigin = "Anonymous";
    img.src = url; //src也可以是从文件选择控件中取得。
    img.onload = () => {
      let [width, height] = getImageSize(img);
      canvas.height = height;
      canvas.width = width;
      ctxt.drawImage(img, 0, 0);
      var data = ctxt.getImageData(~~(width / 2), height - 2, 1, 1).data; //读取整张图片的像素。
      let [r, g, b] = data;
      console.log(33333, r, g, b);
      _this.campaignForm.campaignImageColor = `rgb(${[r, g, b].join()})`;
    };
  }
  couponList: any = [];
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCardTemplateList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.couponList = data.data.list;
      return data;
    });
  }
  saveCampaign() {
    console.log(this.campaignForm, this.$refs.campaignForm);
    // return
    let isCardList = this.confirmSelecteRow.some(item => {
      return !item.groupName
    })
    
    if(isCardList){
      this.$message.error('请检查营销商品【分组名称】填写完整！');
      return
    }
    let isCouponList = this.confirmSelecteCoupon.some(item => {
      return !item.groupName
    })
    if(isCouponList){
      this.$message.error('请检查营销商品【分组名称】填写完整！');
      return
    }

    (this.$refs.campaignForm as any).validate(valid => {
      console.log(valid);
      console.log("[ this.confirmSelecteRow ]", this.confirmSelecteRow);
      if(valid){
        this.newActivitySave({ ...this.campaignForm,activityCouponList:this.confirmSelecteCoupon,activityCardList:this.confirmSelecteRow,activityCode: this.id}).then(res => {
          console.log(res)
          if(res.code==0){
            this.$router.go(-1);
          }else{
            this.$message.error('创建失败，请稍后重试！')
          }
        })
      }
      return
      if (valid) {
        if (this.campaignForm.isCoupon === "1") {
          if (!this.confirmSelecteRow.prizeAmount || !this.confirmSelecteRow.prizeQty) {
            return this.$message.error(t("v210801.please-complete-the"));
          }
          if (
            (this.confirmSelecteRow.prizeQty && !isPositiveIntegerZero(this.confirmSelecteRow.prizeQty)) ||
            Number(this.confirmSelecteRow.prizeQty) === 0
          ) {
            return this.$message.error(t("v210801.the-maximum-number"));
          }
          if (this.confirmSelecteRow.prizeAmount && !isMoney(this.confirmSelecteRow.prizeAmount)) {
            return this.$message.error(t("v210801.the-activity-amount"));
          }
          if (!this.confirmSelecteRow.cardTemplateCode) {
            return this.$message.error(t("marketing.please-select-card-template"));
          } else {
            this.campaignForm.fissionPrize = {
              bindCode: this.confirmSelecteRow.cardTemplateCode,
              prizeAmount: this.confirmSelecteRow.prizeAmount,
              prizeQty: this.confirmSelecteRow.prizeQty
            };
          }
        }
        if (
          dayjs(this.campaignForm.time[1])
            .toDate()
            .getTime() < Date.now()
        ) {
          return this.$message.error(t("v210801.end-time-cannot"));
        }
        this.btnStartLoading();
        this.campaignForm.campaignBeginTime = this.campaignForm.time[0];
        this.campaignForm.campaignEndTime = this.campaignForm.time[1];
        this.campaignForm.storeTypes = (this.campaignForm.storeTypes + "").replace(/[ ]/g, "");
        if (this.isAdd) {
          this.insertFission(this.campaignForm)
            .then(data => {
              this.isAdd
                ? this.$message.success(t("v210801.activity-created-successfully"))
                : this.$message.success(t("v210801.activity-edited-successfully"));
              this.$router.push("/management/fission/fission-campagin");
            })
            .finally(() => {
              this.btnStopLoading();
            });
        } else {
          this.updateFission(this.campaignForm)
            .then(data => {
              this.isAdd
                ? this.$message.success(t("v210801.activity-created-successfully"))
                : this.$message.success(t("v210801.activity-edited-successfully"));
              this.$router.push("/management/fission/fission-campagin");
            })
            .finally(() => {
              this.btnStopLoading();
            });
        }
      }
    });
  }
  // ////
}
